<template>
	<div class="page">
		<fieldset class="search-box">
			<legend>查询条件</legend>
			<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入微信签名" v-model="openid">
				<template #prepend>微信签名</template>
			</el-input>
			<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入卡号" v-model="kaHao">
				<template #prepend>卡号</template>
			</el-input>
			<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入设备号" v-model="deviceSn">
				<template #prepend>设备号</template>
			</el-input>
			<el-button size="small" @click="search">搜索</el-button>

			<el-button type="primary" size="small" @click="clear">批量重置信息</el-button>
		</fieldset>

		<div ref="tableBox" style="margin-top: 10px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
			<el-table element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)"
				element-loading-text="加载中" v-loading="loading" size="small" header-cell-class-name="tableHeader"
				:data="listData" border :max-height="tableHeight" style="width: 100%;">
				<el-table-column fixed="left" prop="devicesn" label="设备号" width="150"></el-table-column>
				<el-table-column label="版本号" width="150">
					<template #default="scope">
						<div @click="setVersion(scope.row)"
							style="cursor: pointer; overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
							{{ scope.row.version ?  (scope.row.version == 'V01' ? 'http' : scope.row.version) : '无版本'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="微信签名" width="250">
					<template #default="scope">
						<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ scope.row.openId }}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="deviceName" label="设备名称" width="120">
				</el-table-column>
				<el-table-column prop="createTime" label="开机日期" width="150">
				</el-table-column>
				<el-table-column prop="daoQiTime" label="到期时间" width="120">
				</el-table-column>
				<el-table-column label="卡号">
					<template #default="scope">
						<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ scope.row.kaHao }}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="balance" label="余额" width="120">
				</el-table-column>
				<el-table-column prop="nianFei" label="年费" width="120">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="400">
					<template #default="scope">
						<div style="display: flex; flex-direction: row;">
							<el-button @click="handleClick(scope.row)" type="text" size="small">基础信息</el-button>
							<el-button @click="showDeviceSub(scope.row)" type="text" size="small">子设备</el-button>
							<el-button @click="showEditBaseInfo(scope.row)" type="text" size="small">余额日期</el-button>
							<el-dropdown style="margin: 0 10px;" trigger="click" @command="handleCommand">
								<span class="el-dropdown-link">
									<el-button type="text" size="small">卡号信息</el-button>
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item :command="{api:1,data:scope.row}">物联卡指定日期用量日志查询
										</el-dropdown-item>
										<el-dropdown-item :command="{api:2,data:scope.row}">物联卡月流量日志</el-dropdown-item>
										<el-dropdown-item :command="{api:5,data:scope.row}">物联卡信息</el-dropdown-item>
										<el-dropdown-item :command="{api:9,data:scope.row}">获取物联网卡充值套餐
										</el-dropdown-item>
									</el-dropdown-menu>
								</template>
							</el-dropdown>

							<el-button @click="baoJingLog(scope.row)" type="text" size="small">报警记录</el-button>
							<el-button @click="getCaiJiLog(scope.row)" type="text" size="small">采集日志</el-button>
							<el-button style="color: red;" @click="deleteDevice(scope.row)" type="text" size="small">删除
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div style="margin-top: 20px; float: right;">
				<el-pagination v-model:currentPage="currentPage" :page-sizes="[30]" :page-size="30"
					layout="total, sizes, prev, pager, next, jumper" :total="count" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>

		<div @click="cancel" class="device-info-card" v-show="showInfo">
			<div style="white-space: nowrap; margin: 0 auto; text-align: center;">
				<el-card id="deviceCard" :style="{'transform':'translateX('+cardX+')'}" @click.stop=""
					v-show="showInfoCard" class="info-card">
					<el-descriptions size="mini"
						:title="'设备详情 (' +  (actionDevice.baseType != '0' ? actionDevice.baseType : '未开机')  +')'"
						:column="4" border>
						<template #extra>
							<!-- <el-button @click="showOtherCard('sub')" size="small">子设备</el-button> -->
						</template>
						<el-descriptions-item>
							<template #label>
								<i class="el-icon-user"></i>
								设备号
							</template>
							<el-tooltip class="item" effect="dark" :content="actionDevice.devicesn"
								placement="top-start">
								<div style="width: 138px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
									{{actionDevice.devicesn}}
								</div>
							</el-tooltip>
						</el-descriptions-item>
						<el-descriptions-item>
							<template #label>
								<i class="el-icon-mobile-phone"></i>
								微信签名
							</template>
							<el-tooltip class="item" effect="dark" :content="actionDevice.openId" placement="top-start">
								<div style="width: 148px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
									{{actionDevice.openId}}
								</div>
							</el-tooltip>
						</el-descriptions-item>
						<el-descriptions-item>
							<template #label>
								<i class="el-icon-location-outline"></i>
								余额
							</template>
							{{actionDevice.balance}}
						</el-descriptions-item>
						<el-descriptions-item>
							<template #label>
								<i class="el-icon-location-outline"></i>
								年费
							</template>
							{{actionDevice.nianFei}}
						</el-descriptions-item>
						<el-descriptions-item>
							<template #label>
								<i class="el-icon-tickets"></i>
								开机时间
							</template>
							{{actionDevice.createTime}}
						</el-descriptions-item>
						<el-descriptions-item>
							<template #label>
								<i class="el-icon-office-building"></i>
								到期时间
							</template>
							{{actionDevice.daoQiTime}}
						</el-descriptions-item>
						<el-descriptions-item>
							<template #label>
								<i class="el-icon-office-building"></i>
								卡号
							</template>
							<el-tooltip class="item" effect="dark" :content="actionDevice.kaHao" placement="top-start">
								<div style="width: 148px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
									{{actionDevice.kaHao}}
								</div>
							</el-tooltip>

						</el-descriptions-item>
					</el-descriptions>
					<div>
						<el-descriptions size="mini" style="margin-top: 20px;" title="报警设置" :column="3" border>
							<el-descriptions-item>
								<template #label>
									<i class="el-icon-user"></i>
									微信报警
								</template>
								{{actionDevice.wxSwitch == 1 ? '开':'关'}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template #label>
									<i class="el-icon-mobile-phone"></i>
									短信报警
								</template>
								{{actionDevice.smSwitch == 1 ? '开':'关'}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template #label>
									<i class="el-icon-location-outline"></i>
									电话报警
								</template>
								{{actionDevice.telSwitch == 1 ? '开':'关'}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template #label>
									<i class="el-icon-location-outline"></i>
									报警次数
								</template>
								{{actionDevice.cishu}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template #label>
									<i class="el-icon-location-outline"></i>
									报警间隔
								</template>
								{{actionDevice.frequency}}分钟/次
							</el-descriptions-item>
						</el-descriptions>

						<el-descriptions size="mini" style="margin-top: 20px;" title="报警电话" :column="3" border>
							<el-descriptions-item v-if="actionDevice.phone1">
								<template #label>
									<i class="el-icon-user"></i>
									联系人
								</template>
								{{actionDevice.phone1}}
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.phone2">
								<template #label>
									<i class="el-icon-user"></i>
									联系人
								</template>
								{{actionDevice.phone2}}
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.phone3">
								<template #label>
									<i class="el-icon-user"></i>
									联系人
								</template>
								{{actionDevice.phone3}}
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.phone4">
								<template #label>
									<i class="el-icon-user"></i>
									联系人
								</template>
								{{actionDevice.phone4}}
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.phone5">
								<template #label>
									<i class="el-icon-user"></i>
									联系人
								</template>
								{{actionDevice.phone5}}
							</el-descriptions-item>
						</el-descriptions>

						<el-descriptions size="mini" style="margin-top: 20px;" title="授权微信" :column="3" border>
							<el-descriptions-item v-if="actionDevice.openId1">
								<template #label>
									<i class="el-icon-user"></i>
									授权微信
								</template>
								<el-tooltip class="item" effect="dark" :content="actionDevice.openId1"
									placement="top-start">
									<div
										style="width: 130px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
										{{actionDevice.openId1}}
									</div>
								</el-tooltip>
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.openId2">
								<template #label>
									<i class="el-icon-user"></i>
									授权微信
								</template>
								<el-tooltip class="item" effect="dark" :content="actionDevice.openId2"
									placement="top-start">
									<div
										style="width: 130px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
										{{actionDevice.openId2}}
									</div>
								</el-tooltip>
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.openId3">
								<template #label>
									<i class="el-icon-user"></i>
									授权微信
								</template>
								<el-tooltip class="item" effect="dark" :content="actionDevice.openId3"
									placement="top-start">
									<div
										style="width: 130px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
										{{actionDevice.openId3}}
									</div>
								</el-tooltip>
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.openId4">
								<template #label>
									<i class="el-icon-user"></i>
									授权微信
								</template>
								<el-tooltip class="item" effect="dark" :content="actionDevice.openId4"
									placement="top-start">
									<div
										style="width: 130px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
										{{actionDevice.openId4}}
									</div>
								</el-tooltip>
							</el-descriptions-item>
							<el-descriptions-item v-if="actionDevice.openId5">
								<template #label>
									<i class="el-icon-user"></i>
									授权微信
								</template>
								<el-tooltip class="item" effect="dark" :content="actionDevice.openId5"
									placement="top-start">
									<div
										style="width: 130px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
										{{actionDevice.openId5}}
									</div>
								</el-tooltip>
							</el-descriptions-item>
						</el-descriptions>
					</div>
				</el-card>
				<el-card @click.stop=""
					:style="{'height':otherHeight,'opacity':opacity,'transform':'translateX('+cardX2+')'}"
					style="overflow: auto;" class="other-card" v-show="showOther">
					<div style="margin-top: 0px; overflow: auto;" v-show="showBase">
						<el-descriptions :key="index" v-for="(item,index) in actionDevice.deviceSubs" size="mini"
							style="margin-top: 20px;" :title="item.subDeviceName" :column="2" border>
							<el-descriptions-item>
								<template #label>
									<i class="el-icon-user"></i>
									设备号
								</template>
								<el-tooltip class="item" effect="dark" :content="item.id" placement="top-start">
									<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
										{{item.id}}
									</div>
								</el-tooltip>
							</el-descriptions-item>
							<el-descriptions-item>
								<template #label>
									<i class="el-icon-user"></i>
									开机时间
								</template>
								<el-tooltip class="item" effect="dark" :content="item.subCreateTime"
									placement="top-start">
									<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
										{{item.subCreateTime}}
									</div>
								</el-tooltip>
							</el-descriptions-item>
						</el-descriptions>
					</div>
				</el-card>
			</div>
		</div>


		<el-dialog v-model="dialog.cardInfo" title="卡详情" width="70%">
			<el-descriptions title="卡详情" border>
				<el-descriptions-item label="msisdn">{{cardInfo.msisdn}}</el-descriptions-item>
				<el-descriptions-item label="iccid">{{cardInfo.iccid}}</el-descriptions-item>
				<el-descriptions-item label="imsi">{{cardInfo.imsi}}</el-descriptions-item>
				<el-descriptions-item label="运营商">
					<el-tag size="small">{{cardInfo.carrier}}</el-tag>
				</el-descriptions-item>
				<el-descriptions-item label="套餐大小">{{cardInfo.data_plan}}</el-descriptions-item>
				<el-descriptions-item label="当月用量,单位M">{{cardInfo.data_usage}}</el-descriptions-item>
				<el-descriptions-item label="卡状态">{{cardInfo.account_status}}</el-descriptions-item>
				<el-descriptions-item label="计费开始日期">{{formatDate(cardInfo.start_date)}}</el-descriptions-item>
				<el-descriptions-item label="计费结束日期">{{formatDate(cardInfo.expiry_date)}}</el-descriptions-item>
				<el-descriptions-item label="激活状态">{{cardInfo.active == 0 ? '未激活' : '激活'}}</el-descriptions-item>
				<el-descriptions-item label="激活日期">{{formatDate(cardInfo.start_date)}}</el-descriptions-item>
				<el-descriptions-item label="剩余流量">{{cardInfo.data_balance}}</el-descriptions-item>
				<el-descriptions-item label="出库日期">{{formatDate(cardInfo.outbound_date)}}</el-descriptions-item>
				<el-descriptions-item label="是否支持短信">{{cardInfo.support_sms}}</el-descriptions-item>
			</el-descriptions>
		</el-dialog>

		<el-dialog v-model="dialog.taocanInfo" title="套餐详情" width="50%">
			<el-descriptions border>
				<el-descriptions-item label="充值套餐id">{{taocan.product_id}}</el-descriptions-item>
				<el-descriptions-item label="充值月份">{{taocan.added_month}}</el-descriptions-item>
				<el-descriptions-item label="套餐名称">{{taocan.product_name}}</el-descriptions-item>
				<el-descriptions-item label="充值价格，单位分">{{taocan.price}}</el-descriptions-item>
			</el-descriptions>
		</el-dialog>

		<el-dialog v-model="dialog.warnInfo" title="报警记录">
			<el-table height="400" :data="warnData">
				<el-table-column label="子设备名称">
					<template #default="scope">
						<div>{{ scope.row.deviceSub.subDeviceName }}</div>
					</template>
				</el-table-column>
				<el-table-column property="subContent" label="报警内容"></el-table-column>
				<el-table-column property="createTime" label="报警时间"></el-table-column>
			</el-table>
		</el-dialog>

		<el-dialog top="5vh" v-model="dialog.dieviceSubWin" width="70vw" title="子设备">
			<div :key="index" v-for="(item,index) in subTypeList">
				<div style="font-weight: 800; margin: 10px 0;">{{item[0] ? item[0].subDeviceType : ''}}</div>
				<el-descriptions :column="4" size="mini" :key="idx" v-for="(sub,idx) in item" border>
					<el-descriptions-item label-class-name="myLabel" min-width="120px" label="设备名">{{sub.subDeviceName}}
					</el-descriptions-item>
					<el-descriptions-item label-class-name="myLabel" min-width="120px" v-if="sub.subDeviceType == 'A'"
						label="报警开关">{{sub.subDeviceData == 1 ? "开" : "关"}}</el-descriptions-item>
					<el-descriptions-item label-class-name="myLabel" min-width="120px" v-if="sub.subDeviceType == 'B'"
						label="控制开关">{{sub.subDeviceData == 1 ? "开" : "关"}}</el-descriptions-item>
					<el-descriptions-item label-class-name="myLabel" min-width="120px" v-if="sub.subDeviceType == 'C'"
						label="采集上限">{{sub.subDeviceData1}}</el-descriptions-item>
					<el-descriptions-item label-class-name="myLabel" min-width="120px" v-if="sub.subDeviceType == 'C'"
						label="采集下限">{{sub.subDeviceData2}}</el-descriptions-item>
					<el-descriptions-item label-class-name="myLabel" min-width="120px" label="是否显示">
						<el-switch @change="uploadDeivceSubState($event,sub)" v-model="sub.state" size="mini"
							active-text="显示" inactive-text="隐藏" :active-value="1" :inactive-value="0" />
					</el-descriptions-item>
				</el-descriptions>
			</div>
		</el-dialog>

		<el-dialog v-model="dialog.caijiWin" title="采集记录">
			<el-tabs type="border-card">
				<el-tab-pane :key="index" v-for="(value,key,index) in caijiList" :label="key">
					<el-table height="300" :data="value">
						<el-table-column property="createTime" label="采集时间"></el-table-column>
						<el-table-column property="subDeviceData" label="采集数据"></el-table-column>
						<el-table-column property="subDeviceDanWei" label="数据单位"></el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>


		<el-dialog v-model="dialog.clearWin" title="清除注册信息">
			<el-input v-model="deviceSnText" rows="6" type="textarea"></el-input>
			<div style="margin-top: 10px; text-align: center;">
				<el-button @click="doClear" type="primary" size="small">清空</el-button>
			</div>
		</el-dialog>


		<el-dialog v-model="dialog.baseInfoEditWin" title="余额时间">
			<div style="display: flex; flex-direction: row;align-items: center; font-size: 13px; margin-bottom: 20px;">
				<div>设备当前余额：{{nowEditDevice.balance}}元 修改为 </div>
				<div style="width: 60px; margin-left: 20px; margin-right: 20px;">
					<el-input v-model="editPostData.balance" size="mini"></el-input>
				</div>
				<div>
					<el-button @click="updateBalance" size="mini" type="text">修改</el-button>
				</div>
			</div>
			<el-alert title="如果到期时间修改幅度比较大,请注意确认是否需要同时调整到期年限,设备续费后,到期时间是根据设备创建时间+年限*360计算得出" type="info" show-icon>
			</el-alert>
			<div
				style="display: flex; flex-direction: row;align-items: center;font-size: 13px; margin-bottom: 20px;margin-top: 20px;">
				<div>设备当前到期时间：{{nowEditDevice.daoQiTime}} 修改为 </div>
				<div style="margin-left: 20px; margin-right: 20px;">
					<el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD" size="mini"
						v-model="editPostData.daoQiTime" type="date" placeholder="修改到期时间">
					</el-date-picker>
				</div>
				<div>
					<el-button @click="updateDaoQiTime" size="mini" type="text">修改</el-button>
				</div>
			</div>

			<div style="display: flex; flex-direction: row;align-items: center;font-size: 13px;">
				<div>设备当前年限：{{nowEditDevice.nianXian}} 修改为 </div>
				<div style="width: 60px; margin-left: 20px; margin-right: 20px;">
					<el-input v-model="editPostData.nianXian" size="mini"></el-input>
				</div>
				<div>
					<el-button @click="updateNianXian" size="mini" type="text">修改</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		selectList,
		deleteItem,
		getDeviceNum,
		getBaoJingLog,
		updateDeviceSubState,
		getDeviceLog,
		uploadDeviceDaoQiTime,
		uploadDeviceBalance,
		uploadDeviceNianXian,
		clearOpenId,
		updateVersion
	} from '../../api/deviceApi.js'
	import {
		usagelog,
		fetch,
		monthlyusagelog,
		cardInfo
	} from '../../api/api.js'
	import {
		ElMessageBox,
		ElMessage,
		ElLoading
	} from 'element-plus'
	export default {
		data() {
			return {
				input1: "",
				tableHeight: 100,
				limit: 30,
				startPage: 0,
				listData: [],
				showInfo: false,
				showInfoCard: false,
				actionDevice: {},
				showBase: true,
				showOther: false,
				cardX: '0px',
				otherHeight: "0px",
				opacity: 0,
				cardX2: '0px',
				openid: "",
				kaHao: "",
				deviceSn: "",
				currentPage: 1,
				count: 0,
				loading: false,
				warnData: [],
				dialog: {
					cardInfo: false,
					taocanInfo: false,
					warnInfo: false,
					dieviceSubWin: false,
					caijiWin: false,
					baseInfoEditWin: false,
					clearWin: false
				},
				deviceSnText: "",
				cardInfo: {},
				taocan: {},
				subTypeList: [],
				caijiPage: 0,
				caijiList: {},
				nowEditDevice: {},
				editPostData: {}

			}
		},

		mounted() {
			this.tableInit()
			this.list()
			getDeviceNum(-1).then(res => {
				this.count = res.data.data
			})
		},

		methods: {
			//表格高度自适应
			tableInit: function() {
				var that = this
				let tableBox = this.$refs.tableBox
				this.tableHeight = tableBox.offsetHeight - 60
				setTimeout(function() {
					tableBox.children[0].style.maxHeight = that.tableHeight + "px"
				}, 0)
			},

			clear: function() {
				this.dialog.clearWin = true
			},

			doClear: function() {
				let data = this.getDeviceSnList(this.deviceSnText)
				var that = this
				clearOpenId({
					deviceSnList: data
				}).then(res => {
					that.$notify.success({
						title: '成功',
						message: '重置了' + res.data.data + "个设备"
					});
				})
			},

			getDeviceSnList: function(data) {
				let deviceSnListDev = data.split(/[(\r\n)\r\n]+/)
				for (let i = 0; i < deviceSnListDev.length; i++) {
					if (deviceSnListDev[i].length != 15) {
						if (i == deviceSnListDev.length - 1 && deviceSnListDev[i].length < 5) {
							deviceSnListDev.pop()
						} else {
							this.$notify.error({
								title: '错误',
								message: '卡号信息有误'
							});
							return null
						}
					}
				}
				return deviceSnListDev
			},

			showEditBaseInfo: function(data) {
				var that = this
				that.dialog.baseInfoEditWin = true
				that.nowEditDevice = data
				that.editPostData = {}
			},

			updateBalance: function() {
				var that = this
				if (!this.editPostData.balance || this.editPostData.balance < 0) {
					ElMessage({
						type: 'error',
						message: '余额不能为空，且不能小于0',
					})
					return
				}
				uploadDeviceBalance({
					deviceSn: that.nowEditDevice.devicesn,
					balance: that.editPostData.balance
				}).then(() => {
					this.list()
					ElMessage({
						type: 'success',
						message: '修改成功',
					})
				})
			},

			updateDaoQiTime: function() {
				var that = this
				if (!this.editPostData.daoQiTime) {
					ElMessage({
						type: 'error',
						message: '时间参数不能为空',
					})
					return
				}
				uploadDeviceDaoQiTime({
					deviceSn: that.nowEditDevice.devicesn,
					timer: that.editPostData.daoQiTime
				}).then(() => {
					this.list()
					ElMessage({
						type: 'success',
						message: '修改成功',
					})
				})
			},

			updateNianXian: function() {
				var that = this
				if (!this.editPostData.nianXian || this.editPostData.nianXian < 0) {
					ElMessage({
						type: 'error',
						message: '年限参数不能为空，且不能小于0',
					})
					return
				}
				uploadDeviceNianXian({
					deviceSn: that.nowEditDevice.devicesn,
					balance: that.editPostData.nianXian
				}).then(() => {
					this.list()
					ElMessage({
						type: 'success',
						message: '修改成功',
					})
				})
			},

			getCaiJiLog: function(data) {
				var that = this
				getDeviceLog({
					page: that.caijiPage,
					deviceSn: data.devicesn
				}).then(res => {
					that.caijiList = res.data.data
					that.dialog.caijiWin = true
				})
			},

			uploadDeivceSubState: function(val, sub) {
				console.log(sub)
				updateDeviceSubState({
					deviceSn: sub.deviceSn,
					deviceSubId: sub.subDeviceId,
					state: sub.state
				}).then(() => {
					ElMessage({
						type: 'success',
						message: '修改成功',
					})
				}).catch(() => {
					ElMessage({
						type: 'error',
						message: '修改失败',
					})
				})
			},

			showDeviceSub: function(data) {
				this.dialog.dieviceSubWin = true
				let subList = data.deviceSubs
				let subTypeList = [
					[],
					[],
					[]
				]
				for (let i = 0; i < subList.length; i++) {
					switch (subList[i].subDeviceType) {
						case "A":
							subTypeList[0].push(subList[i])
							break
						case "B":
							subTypeList[1].push(subList[i])
							break
						case "C":
							subTypeList[2].push(subList[i])
							break
					}
				}
				this.subTypeList = subTypeList
			},

			//列表查询
			list: function() {
				var that = this
				that.loading = true
				selectList({
					limit: that.limit,
					startPage: that.currentPage - 1
				}).then(res => {
					that.listData = res.data.data.deviceList
					that.loading = false
				})
			},

			//翻页组件数据
			getSize: function() {

			},

			setVersion: function(data) {
				console.log(data)
				let that = this
				this.$prompt('请输入版本号', '修改版本号', {
						distinguishCancelAndClose: true,
						confirmButtonText: '保存',
						cancelButtonText: '放弃修改'
					})
					.then(({ value }) => {
						updateVersion({
							deviceSn:data.devicesn,
							version:value
						}).then(()=>{
							that.$message({
								type: 'success',
								message: '操作成功'
							});
							that.list()
						})
					})
			},

			//报警记录
			baoJingLog: function(data) {
				var that = this
				const loading = ElLoading.service({
					lock: true,
					text: '正在拉取数据',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				getBaoJingLog(data.devicesn).then(res => {
					that.dialog.warnInfo = true
					that.warnData = res.data.data.all
					loading.close()
				}).catch(() => {
					loading.close()
				})
			},

			formatDate: function(date) {
				date = new Date(date * 1000);
				var YY = date.getFullYear() + '-';
				var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
				return YY + MM + DD;
			},


			handleClick: function(item) {
				this.showInfo = true
				this.showInfoCard = true
				this.actionDevice = item
				this.showBase = true
			},

			cancel: function() {
				this.showInfo = false
				this.showInfoCard = false
				this.showOther = false
				this.cardX = "0px"
				this.cardX2 = "0px"
			},

			showOtherCard: function(type) {
				var that = this
				var same = false
				switch (type) {
					case 'sub':
						console.log(that.showBase)
						if (that.showBase) {
							same = true
						} else {
							that.showBase = true
						}
						break
				}
				if (this.showOther && same) {
					this.cardX = "0px"
					this.cardX2 = "0px"
					that.opacity = 0
					setTimeout(function() {
						that.showOther = false
						that.showBase = false
					}, 1000)
				} else {
					let deviceCard = document.getElementById("deviceCard")
					this.otherHeight = (deviceCard.offsetHeight - 3) + "px"
					this.cardX = "-200px"
					this.showOther = true
					setTimeout(function() {
						that.cardX2 = "470px"
						that.opacity = 1
					}, 0)
				}
			},

			handleCommand: function(data) {
				var that = this
				switch (data.api) {
					case 1:
						ElMessageBox.prompt('请输入查询日期，格式为YYYYMMDD', '查询日期', {
								confirmButtonText: '提交',
								cancelButtonText: '取消',
							})
							.then(({
								value
							}) => {
								usagelog({
									iccId: data.data.kaHao,
									dataTime: value
								}).then(res => {
									var pattern = /(\d{4})(\d{2})(\d{2})/;
									var formatedDate1 = value.replace(pattern, '$1/$2/$3');
									var ddate1 = new Date(formatedDate1);
									var Y = ddate1.getFullYear();
									var M = ddate1.getMonth() + 1;
									var D = ddate1.getDate();
									var times = Y + (M < 10 ? "-0" : "-") + M + (D < 10 ? "-0" : "-") + D;
									ElMessageBox.alert(`该卡号在${times}的流量使用为${res.data.data[0].data_usage}M`,
										'查询结果', {
											confirmButtonText: 'OK',
										})
								}).catch(() => {
									ElMessage({
										type: 'error',
										message: '查询失败',
									})
								})
							})
						break
					case 2:
						ElMessageBox.prompt('请输入查询日期，格式为YYYYMM', '查询日期', {
								confirmButtonText: '提交',
								cancelButtonText: '取消',
							})
							.then(({
								value
							}) => {
								monthlyusagelog({
									iccId: data.data.kaHao,
									dataTime: value
								}).then(res => {
									var pattern = /(\d{4})(\d{2})/;
									var formatedDate1 = value.replace(pattern, '$1/$2');
									var ddate1 = new Date(formatedDate1);
									var Y = ddate1.getFullYear();
									var M = ddate1.getMonth() + 1;
									var times = Y + (M < 10 ? "-0" : "-") + M;
									ElMessageBox.alert(
										`该卡号在${times}当月的流量使用为${res.data.data[0].data_usage}M`,
										'查询结果', {
											confirmButtonText: 'OK',
										})
								}).catch(() => {
									ElMessage({
										type: 'error',
										message: '查询失败',
									})
								})
							})
						break
					case 5:
						that.cardInfo = {}
						cardInfo({
							iccId: data.data.kaHao,
						}).then(res => {
							that.cardInfo = res.data.data
							switch (that.cardInfo.account_status) {
								case 0:
									that.cardInfo.account_status = "未知"
									break
								case 1:
									that.cardInfo.account_status = "测试期"
									break
								case 2:
									that.cardInfo.account_status = "沉默期"
									break
								case 3:
									that.cardInfo.account_status = "使用中"
									break
								case 4:
									that.cardInfo.account_status = "停机"
									break
								case 5:
									that.cardInfo.account_status = "停机保号"
									break
								case 6:
									that.cardInfo.account_status = "预销号"
									break
								case 7:
									that.cardInfo.account_status = "销号"
									break
							}
							that.dialog.cardInfo = true
						}).catch(() => {
							ElMessage({
								type: 'error',
								message: '查询失败',
							})
						})
						break
					case 9:
						that.taocan = {}
						fetch({
							iccId: data.data.kaHao,
						}).then(res => {
							that.taocan = res.data.data.products[0]
							that.dialog.taocanInfo = true
						}).catch(() => {
							ElMessage({
								type: 'error',
								message: '查询失败',
							})
						})
						break
				}
			},

			deleteDevice: function(data) {
				var that = this
				this.$confirm('确定删除该设备？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteItem(data.devicesn).then(res => {
						if (res.data.data) {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.list();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},

			search: function() {
				var that = this
				if (that.openid || that.kaHao || that.deviceSn) {
					selectList({
						openId: that.openid,
						devicesn: that.deviceSn,
						kaHao: that.kaHao,
						limit: that.limit,
						startPage: that.startPage
					}).then(res => {
						that.listData = res.data.data.deviceList
					})
				} else {
					selectList({
						limit: that.limit,
						startPage: that.startPage
					}).then(res => {
						that.listData = res.data.data.deviceList
					})
				}

			},

			handleCurrentChange: function(data) {
				this.currentPage = data
				this.list()
			}
		}
	}
</script>

<style scoped="scoped">
	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}

	.device-info-card {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .5);
		z-index: 99;
	}

	.info-card {
		width: 800px;
		padding: 30px;
		position: absolute;
		top: 5vh;
		left: 50%;
		margin-left: -400px;
		transition: 1s;
		z-index: 2;
	}

	.other-card {
		width: 400px;
		margin-left: 20px;
		position: absolute;
		left: 50%;
		top: 5vh;
		margin-left: -200px;
		transition: 1s;
	}
</style>
